import './main.css';
import { Elm } from './Main.elm';
import registerServiceWorker from './registerServiceWorker';

let storageKey = "store";
let flags = localStorage.getItem(storageKey);
let app = Elm.Main.init({flags: flags});

if (app.ports && app.ports.storeCache) {
  app.ports.storeCache.subscribe((val) => {

    if (val === null) {
      localStorage.removeItem(storageKey);
    } else {
      localStorage.setItem(storageKey, JSON.stringify(val));
    }

    // Report that the new session was stored succesfully.
    setTimeout(function() { app.ports.onStoreChange.send(val); }, 0);
  });

  // Whenever localStorage changes in another tab, report it if necessary.
  window.addEventListener("storage", function(event) {
    if (event.storageArea === localStorage && event.key === storageKey) {
      app.ports.onStoreChange.send(event.newValue);
    }
  }, false);
}

registerServiceWorker();
